<template>
  <div class="teacherListBg">
    <div class="teacherListImg">
      <img src="images/newsDetail.png" />
<!--      <p>这里有158位教师 带你一起学</p>-->
    </div>
    <div class="teacherListWh">
      <div  class="teacherListClassify teacheerFlex">
        <div class="teacherAvatarFlex" v-for="(item,index) in teacherList" :key="index" @click="goteacherDetail(item)">
          <img :src="item.logo?item.logo:'images/zanwuAvatar.png'" class="teacherAvatar"/>
          <div>
            <p class="teacherName">
              <span>{{item.name}}</span>
            </p>
            <p>{{item.position}}</p>
            <p>{{item.profiles}}</p>
            <p>擅长：{{item.goodAt}}</p>
            <p>课程数：{{item.courseNumber}}</p>
          </div>
        </div>
      </div>
      <div class="newsPafeMr">
        <el-pagination
            class="text_center"
            background
            @current-change="handleTeacherList"
            :current-page.sync="currentPage"
            :page-size="size"
            layout="total,  prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { teacherList} from "@/api";
export default  {
  data(){
    return{
      rankArr:[
        {id:1,name:'综合'},
        {id:2,name:'学习人数'},
        {id:3,name:'发布时间'},
        {id:4,name:'难易度'},
      ],
      classfiyArr:[
        {id:1,name:'全部'},
        {id:1,name:'网站运营'},
        {id:1,name:'网络推广'},
        {id:1,name:'网络设计'},
      ],
      currentPage: 1, //初始页
      size: 10,
      total: 0,
      teacherList:[]
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getTeacherList()
  },
  methods:{
    // 分页
    handleTeacherList: function (currentPage) {
      this.currentPage = currentPage;
      this.getTeacherList();
    },
  //  获取教师列表
    getTeacherList(){
      let data={
        page:this.currentPage,
        size:this.size
      }
      teacherList(data).then(res => {
        this.teacherList=res.data.records;
        this.total = JSON.parse(res.data.total) ;
      })
    },
  //  去教师详情
    goteacherDetail(item){
      sessionStorage.setItem('teacherDetail',JSON.stringify(item))
      let routeUrl = this.$router.resolve({
        path: "/teacherDetail",
        query:{id:item.id}
      });
      window.open(routeUrl.href);
    }
  }
}
</script>
<style scoped>
@import "../../../public/css/teacherList.css";
</style>
